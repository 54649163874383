<template>
  <div>
    <ciam-page-title :title="concatenated"></ciam-page-title>
    <slot></slot>
  </div>
</template>
<script>
import CiamPageTitle from '@/components/CiamPageTitle.vue';

export default {
  components: {
    CiamPageTitle,
  },
  name: 'ciam-page',
  props: {
    titleSuffix: {
      type: String,
      default: '',
    },
  },
  render() {
    return null;
  },
  computed: {
    concatenated() {
      let title = 'Cloud-IAM';
      if (this.titleSuffix !== undefined && this.titleSuffix.trim().length > 0) {
        title += ' | ' + this.titleSuffix.trim();
      }
      return title;
    },
  },
};
</script>