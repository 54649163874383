<template>
  <div class="ml-3 relative" v-if="currentUser">
    <ciam-dropdown>
      <template v-slot:menu="parent">
        <button
          class="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          id="user-menu"
          aria-haspopup="true"
          data-e2e="menu-dropdown"
          @click="
            parent.toggle();
            listOrganizations();
          "
        >
          <span class="sr-only">{{ $t('menu.open') }}</span>
          <ciam-icon class="h-8 w-8 rounded-full fa-2x" name="fa-user-circle"></ciam-icon>
        </button>
      </template>

      <template v-slot:dropdown="parent">
        <ciam-dropdown-menu-items>
          <ciam-dropdown-menu-item-text>
            <ciam-text>{{ $t('menu.signedAs') }}</ciam-text>
            <ciam-text class="block bold text-gray-900 truncate">{{ currentUser.email }}</ciam-text>
          </ciam-dropdown-menu-item-text>
        </ciam-dropdown-menu-items>
        <ciam-dropdown-menu-items>
          <ciam-dropdown-menu-item-link @click="goToSettingsUser(parent.close)">
            <ciam-text>{{ $t('menu.settings') }}</ciam-text>
          </ciam-dropdown-menu-item-link>
        </ciam-dropdown-menu-items>
        <ciam-dropdown-menu-items>
          <ciam-dropdown-menu-item-text class="flex justify-between">
            <span>{{ $t('menu.organisations') }}</span>
            <ciam-link @click="openCreateOrganizationToggle()">
              Add
            </ciam-link>
            
          </ciam-dropdown-menu-item-text>
          <Promised :promise="organizations">
            <template v-slot:pending>
              <ciam-dropdown-menu-item-text>
                <ciam-loader class="py-4"></ciam-loader>
              </ciam-dropdown-menu-item-text>
            </template>

            <template v-slot:rejected="problem">
              <ciam-text>{{ $t('menu.organisations', { problem: problem }) }}</ciam-text>
            </template>

            <template v-slot="organizations">
              <div>
                <div v-for="(organization, index) in organizations" :key="index">
                  <ciam-dropdown-menu-item-text
                    v-if="organization.id === $route.query.organization_id"
                    class="flex justify-between"
                  >
                    <span>{{ organization.name }}</span>
                    <ciam-link @click="editOrganization(organization.id)"
                      >{{ $t('organization.menu.edit') }}
                    </ciam-link>
                  </ciam-dropdown-menu-item-text>

                  <ciam-dropdown-menu-item-link v-else @click="switchOrganization(organization.id)">
                    {{ organization.name }}
                  </ciam-dropdown-menu-item-link>
                </div>
              </div>
            </template>
          </Promised>
        </ciam-dropdown-menu-items>
        <ciam-dropdown-menu-items>
          <ciam-dropdown-menu-item-link data-e2e="logout" @click="logout()">
            {{ $t('menu.logout') }}
          </ciam-dropdown-menu-item-link>
        </ciam-dropdown-menu-items>
      </template>
    </ciam-dropdown>
    <ciam-card>
      <b-modal
        :active.sync="isCreateOrganizationOpened"
        has-modal-card
        trap-focus
        :can-cancel="['button']"
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        :width="600"
      >
        <standard-modal v-if="isCreateOrganizationOpened" :buttons="buttons" @result="createOrganization()">
          <template v-slot:header>{{ $t('settings.user.organizationOnDemand.title') }}</template>
          <template v-slot:default>
            <p>{{ $t('settings.user.organizationOnDemand.text') }}</p>
            <p style="padding-top: 30px">
              <ciam-input type="text" v-model="newOrganizationName" />
            </p>
          </template>
        </standard-modal>
      </b-modal>
    </ciam-card>
  </div>
</template>

<script>
import CiamIcon from '@/components/CiamIcon';
import CiamDropdown from '@/components/CiamDropdown';
import CiamDropdownMenuItemLink from '@/components/CiamDropdownMenuItemLink';
import CiamDropdownMenuItemText from '@/components/CiamDropdownMenuItemText';
import CiamDropdownMenuItems from '@/components/CiamDropdownMenuItems';
import CiamText from '@/components/CiamText';
import OrganizationService from '@/pages/organizations/OrganizationService';
import CiamLink from '@/components/CiamLink';
import { routeNames } from '@/router';
import StandardModal from '@/components/StandardModal';
import CiamInput from '@/components/CiamInput';
import { Notification } from 'vue-notifyjs';

import CiamButton from '@/components/CiamButton';

export default {
  name: 'ciam-login-menu',
  components: {
    CiamLink,
    CiamText,
    CiamDropdown,
    CiamDropdownMenuItemText,
    CiamDropdownMenuItemLink,
    CiamDropdownMenuItems,
    CiamIcon,
    CiamInput,
    StandardModal
  },
  data() {
    return {
      currentUser: null,
      organizations: Promise.resolve(),
      newOrganizationName: null,
      isCreateOrganizationOpened: false,
      buttons: [
        { text: this.$t('actions.cancel') },
        {
          text: this.$t('actions.save'),
          classes: ['is-info', 'is-outlined'],
          returnVal: true,
        },
      ],
    };
  },
  async mounted() {
    this.currentUser = await this.$keycloak.loadUserInfo();
    this.organizations = OrganizationService.getAll();
  },
  methods: {
    openCreateOrganizationToggle() {
      this.isCreateOrganizationOpened = true
    },
    listOrganizations() {
      this.organizations = OrganizationService.getAll();
    },
    logout() {
      this.$keycloak.logout();
    },
    goToSettingsUser(onDone) {
      this.$router
        .push({
          name: routeNames.SettingsUser,
        })
        .catch(() => {})
        .finally(() => onDone());
    },
    switchOrganization(organization_id) {
      return this.$router.ciamUpdateQuery({ organization_id: organization_id }).finally(() => {
        location.reload();
      });
    },
    editOrganization(organization_id) {
      return this.$router
        .push({
          name: routeNames.OrganizationDetails,
          query: {
            organization_id: organization_id,
          },
          params: {
            id: organization_id,
          },
        })
        .catch(() => {});
    },
    async createOrganization() {
      try {
        const response = await OrganizationService.createOrganizationWithName(this.currentUser.id, this.newOrganizationName)
        if(response.status === 201) {
          Notification.notify({
            type: 'info',
            message: this.$t(('notifications.organizationCreated'))
          })
        }     
      } catch (err) {
        Notification.notify({
          type: 'danger',
          message: this.$t(('notifications.organizationCreationError'))
        })
      }
      this.isCreateOrganizationOpened = false;
      this.newOrganizationName = null

    }
  },
  computed: {},
};
</script>

<style scoped lang="scss">
.given-name {
  padding-right: 5px;
}

.menu-item-text {
  @apply block px-4 py-2 text-sm text-gray-500;
}

.menu-item-link {
  @apply block px-4 py-2 text-sm font-medium text-indigo-600 hover:text-indigo-900 hover:bg-gray-100;
}
</style>
