/*eslint no-undef: "off"*/
window.HW_config = {
  selector: '.js-changelog', // CSS selector where to inject the badge
  account: 'JVroPJ',
};

(function setup_headwayapp() {
  if (document.querySelector('.js-changelog') === null) {
    return setTimeout(setup_headwayapp, 100);
  }

  const script = document.createElement('script');
  script.src = 'https://cdn.headwayapp.co/widget.js';
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
})();
