<template>
  <div class="ciam-loader">
    <ciam-icon name="fa-circle-notch" size="2x" spin></ciam-icon>
  </div>
</template>

<script>
export default {
  name: 'ciam-loader',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-loader {
  @apply flex justify-center items-center;
}
</style>
