<template>
  <div class="container cursor" :class="blinkAnimation">
    <code v-text="displayPassword" @click="always || showPassword()" :class="blinkAnimation" />
    <b-tooltip :label="$t('actions.copy')" type="is-info" position="is-right">
      <button @click="copyPasswordToClipboard()">
        <b-icon v-if="clipboardCopy && isClipboardAvailable" class="cursor" pack="fas" icon="copy" />
      </button>
    </b-tooltip>
  </div>
</template>

<script>
import { Notification } from 'vue-notifyjs';

export default {
  name: 'PasswordField',
  data() {
    return {
      hidden: true,
    };
  },
  props: {
    /**
     * clipboard-copy: Active/Désactive le bouton de copie du mot de passe dans le presse-papiers
     */
    clipboardCopy: {
      type: Boolean,
      default: true,
    },
    /**
     * always : Affiche en permanence le mot de passe si la propriété vaut true
     */
    always: {
      type: Boolean,
      default: false,
    },
    /**
     * show-duration : Le temps pendant lequel le mot de passe reste affiché en secondes
     */
    showDuration: {
      type: Number,
      default: 3,
      validator: (v) => v && v > 0,
    },
    /**
     * password: La valeur du mot de passe
     */
    password: {
      type: String,
      required: true,
    },
    /**
     * mask-char: Le caractère servant de masque au mot de passe lorsqu'il est caché
     */
    maskChar: {
      type: String,
      default: '•',
      validator: (v) => v && v.trim() && v.trim().length == 1,
    },
  },
  methods: {
    showPassword() {
      this.hidden = false;
      setTimeout(() => (this.hidden = true), this.showDuration * 1000);
    },
    async copyPasswordToClipboard() {
      await navigator.clipboard.writeText(this.password);
      Notification.notify({
        type: 'info',
        message: this.$t('notifications.passwordCopied'),
      });
    },
  },
  mounted() {
    this.hidden = !this.always;
  },
  computed: {
    blinkAnimation() {
      return this.hidden || this.always || 'blink';
    },
    displayPassword() {
      return this.hidden ? this.maskChar.repeat(this.password.length) : this.password;
    },
    isClipboardAvailable() {
      return navigator && navigator.clipboard && typeof navigator.clipboard.writeText == 'function';
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor {
  cursor: pointer;
}

.container {
  background-color: #f5f5f5;
  text-align: center;
  margin: 12px 0;
  border-radius: 10px;
  padding: 8px;

  &.blink {
    animation: blink-container 1s ease;
  }

  button {
    border: none;
    background: none;
    padding: 0;
  }

  code {
    font-size: 16pt;
    background: none;

    &.blink {
      animation: blink-code 1s ease;
    }
  }
}

@keyframes blink-container {
  50% {
    background-color: #ff3860;
  }
}

@keyframes blink-code {
  50% {
    color: white;
  }
}
</style>
