/**
 * push to datalayer and never fail
 * @param args
 */
export function push(...args) {
  try {
    (window._paq || []).push(...args);
  } catch (_err) {
    // do nothing
  }
}
export function analyticsInit() {
  window.history.pushState = new Proxy(window.history.pushState, {
    apply: (target, thisArg, argArray) => {
      // trigger here what you need
      push(['setDocumentTitle', document.domain + '/' + document.title]);

      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      push(['deleteCustomVariables', 'page']);

      push(['trackPageView']);

      return target.apply(thisArg, argArray);
    },
  });

  (function () {
    let _mtm = (window._mtm = window._mtm || []);
    _mtm.push({ 'mtm.startTime': new Date().getTime(), event: 'mtm.Start' });
    let d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.async = true;

    // this project does not currently use env. vars for config management, falling back on old ways
    const config = {
      'app.cloud-iam.com':
        'https://cdn.matomo.cloud/cloudiam.matomo.cloud/container_nlo0h72K_dev_99bf390acb1e8cf220c4cc15.js',
      'staging-app.cloud-iam.com':
        'https://cdn.matomo.cloud/cloudiam.matomo.cloud/container_nlo0h72K_staging_1de8fb91cdb831f22c3d6f2e.js',
    };

    g.src =
      process.env.VUE_APP_MATOMO_TAG_MANAGER_URL || config[location.hostname]
        ? config[location.hostname]
        : // fallback to dev environment matomo tag manager
          'https://cdn.matomo.cloud/cloudiam.matomo.cloud/container_nlo0h72K_dev_99bf390acb1e8cf220c4cc15.js';
    s.parentNode.insertBefore(g, s);
  })();
}
