import Vue from 'vue';
import moment from 'moment';
import { getStartingLocale } from '@/i18n';
import { removeAnsi } from 'ansi-parser';

Vue.filter('truncate', function (text, length, clamp) {
  clamp = clamp || '...';
  return text.length > length ? text.slice(0, length) + clamp : text;
});

Vue.filter('formatDate', function (value) {
  moment.locale(getStartingLocale());
  if (value) {
    return moment(value, 'YYYY-MM-DD HH:mmZZ').fromNow();
  }
});

Vue.filter('uppercaseFirstLetter', function (value) {
  return value.charAt(0).toUpperCase() + value.slice(1);
});

Vue.filter('uppercase', function (value) {
  return value.toUpperCase();
});

Vue.filter('adaptivelyFormatDate', function (value) {
  moment.locale(getStartingLocale());
  if (value) {
    const inDayFromNow = moment(value).diff(moment(new Date()), 'day');
    if (inDayFromNow < -1) {
      return new Date(value).toLocaleString();
    }
    return moment(value, 'YYYY-MM-DD HH:mmZZ').fromNow();
  }
});

Vue.filter('localeDate', function (value) {
  if (value) {
    return new Date(value).toLocaleString();
  }
});

Vue.filter('localeTime', function (value, useUTC = false) {
  if (value) {
    const options = {
      hour12: false,
      timeZone: useUTC ? 'UTC' : undefined,
    };
    return new Date(value).toLocaleTimeString(undefined, options);
  }
});

Vue.filter('take', function (value, length) {
  if (value) {
    return value.substring(0, length);
  }
});

Vue.filter('skip', function (value, length) {
  if (value) {
    return value.substring(length);
  }
});

Vue.filter('removeAnsi', function (text) {
  if (text) {
    return removeAnsi(text);
  }
});

Vue.filter('formatPriceInEuro', function (price) {
  return new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'EUR',
  }).format(parseFloat(price));
});

Vue.filter('formatNumber', function (number) {
  return number.toLocaleString('fr-FR');
});

Vue.filter('pretty', function (value) {
  if (value) {
    return JSON.stringify(value, null, 2);
  } else {
    return '';
  }
});
