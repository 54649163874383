<template>
  <div class="ciam-card-footer"><slot></slot></div>
</template>

<script>
export default {
  name: 'ciam-card-footer',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-card-footer {
  @apply mt-6 flex items-center justify-end space-x-4 px-4 py-5 sm:p-6;
}
</style>
