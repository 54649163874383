<template>
  <div class="relative inline-block text-left">
    <div>
      <slot name="menu" :open="open" :close="close" :toggle="toggle"></slot>
    </div>

    <div ref="dropdown">
      <transition name="ease">
        <div
          v-if="show"
          class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none w-80 z-50"
          role="menu"
          aria-orientation="vertical"
          tabindex="-1"
        >
          <slot name="dropdown" :open="open" :close="close" :toggle="toggle"></slot>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside';
const { bind, unbind } = vClickOutside.directive;

export default {
  name: 'ciam-dropdown',
  props: {},
  components: {},
  data() {
    return {
      show: false,
    };
  },
  methods: {
    toggle() {
      if (this.show) {
        this.close();
      } else {
        this.open();
      }
    },
    open() {
      this.show = true;

      // listen to outside click
      bind(this.$refs.dropdown, { value: this.onClickOutside });
    },
    close() {
      this.show = false;

      // remove click outside listener
      unbind(this.$refs.dropdown);
    },
    onClickOutside(event) {
      this.close();
    },
  },
  mounted() {},
  beforeDestroy() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ease-enter-active {
  @apply transition ease-out duration-100 z-50;
}

.ease-enter {
  @apply transform opacity-0 scale-95;
}

.ease-enter-to {
  @apply transform opacity-100 scale-100;
}

.ease-leave-active {
  @apply transition ease-in duration-75;
}

.ease-leave {
  @apply transition ease-in duration-75;
}

.ease-leave-to {
  @apply transform opacity-0 scale-95;
}
</style>
