import { problemAwareHttp } from '@/http';

export default class CommonService {
  static async get(resources, id) {
    const response = await problemAwareHttp.get(`/${resources}/${id}`);

    if (!response) {
      return Promise.reject();
    }

    const { data: resource } = response;

    return resource;
  }
}
