<template>
  <div class="ciam-card-content-line" :class="type">
    <dt class="ciam-card-content-line-label">
      <slot name="label"></slot>
    </dt>
    <dd class="ciam-card-content-line-content">
      <slot name="content"></slot>
    </dd>
  </div>
</template>

<script>
export default {
  name: 'ciam-card-content-line',
  props: {
    type: {
      type: String,
      default: 'split',
      validator: (v) => ['split', 'full-width', 'split-content-component', 'stacked'].includes(v),
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-card-content-line {
  @apply py-4 sm:py-5 sm:px-6;

  &.stacked {
    @apply grid-rows-2;
  }

  &.split,
  &.split-content-component {
    @apply sm:grid sm:grid-cols-3 sm:gap-4;
  }

  &.full-width {
    .ciam-card-content-line-content {
      @apply py-4;
    }
  }

  &.split-content-component {
    .ciam-card-content-line-content {
      @apply pt-0 mt-0;
    }
  }
}

.ciam-card-content-line-label {
  @apply text-sm font-medium text-gray-500;
}

.ciam-card-content-line-content {
  @apply mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2;
}
</style>
