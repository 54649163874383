class Problem {
  constructor(status, type, title, detail) {
    this.status = status;
    this.type = type;
    this.title = title;
    this.detail = detail;
  }
}

export default Problem;
