<template>
  <div class="app-container">
    <ciam-navigation>
      <template v-slot:left> </template>
      <template v-slot:right>
        <div class="js-changelog"></div>
        <ciam-login-menu></ciam-login-menu>
      </template>
    </ciam-navigation>
    <notifications></notifications>
    <main id="app" class="app-content">
      <router-view />
    </main>
    <ciam-footer></ciam-footer>
    <ciam-deployment-credentials-modal />
  </div>
</template>

<script>
import CiamLoginMenu from '@/components/CiamLoginMenu';
import CiamDeploymentCredentialsModal from '@/pages/deployments/CiamDeploymentCredentialsModal';
import CiamFooter from '@/components/CiamFooter';
import CiamNavigation from '@/components/CiamNavigation';

export default {
  name: 'App',
  components: {
    CiamLoginMenu,
    CiamDeploymentCredentialsModal,
    CiamFooter,
    CiamNavigation,
  },
  data() {
    return { langs: ['en', 'fr'] };
  },
  computed: {
    isAdmin() {
      return this.$keycloak.hasRealmRole('ADMIN');
    },
  },
  mounted() {
    try {
      let redirect_uri = localStorage.getItem('register_redirect_uri');
      localStorage.removeItem('register_redirect_uri');
      if (redirect_uri && redirect_uri.includes('app.cloud-iam.com')) {
        location.href = redirect_uri;
      }
    } catch (_err) {
      // do nothing
    }
  },
};

require('./pages/Headwayapp');
</script>

<style lang="scss">
.app-container {
  @apply bg-gray-100;
}
#app {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  @apply bg-gray-100 max-w-6xl mx-auto my-10;
}

.vue-notifyjs.notifications {
  .list-move {
    transition: transform 0.3s, opacity 0.4s;
  }

  .list-item {
    display: inline-block;
    margin-right: 10px;
  }

  .list-enter-active {
    transition: transform 0.2s ease-in, opacity 0.4s ease-in;
  }

  .list-leave-active {
    transition: transform 1s ease-out, opacity 0.4s ease-out;
  }

  .list-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .list-leave-to {
    opacity: 0;
    transform: scale(1.2, 0.7);
  }
}
</style>
