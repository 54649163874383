<template>
  <dl class="ciam-card-content-lines">
    <slot></slot>
  </dl>
</template>

<script>
export default {
  name: 'ciam-card-content-lines',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-card-content-lines {
  @apply sm:divide-y sm:divide-gray-200;
}
</style>
