<template>
  <a
    v-bind="{ ...$props, ...$attrs }"
    class="ciam-button"
    :class="{ loading: loading }"
    @click="onClick($event)"
    :disabled="loading || disabled"
    :href="href"
  >
    <div class="ciam-button-left" v-if="hasSlot('left') && !loading">
      <slot name="left"></slot>
    </div>
    <slot v-if="!loading"></slot>
    <ciam-icon name="fa-spinner" class="animate-spin" v-if="loading"></ciam-icon>
    <div class="ciam-button-right" v-if="hasSlot('right') && !loading">
      <slot name="right"></slot>
    </div>
  </a>
</template>

<script>
import CiamIcon from '@/components/CiamIcon';

export default {
  name: 'ciam-button',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    to: {
      // helper to let the button go to a specified route
      type: Object,
      required: false,
    },
    targetUrl: {
      // link without the router
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  components: {
    CiamIcon,
  },
  data() {
    return {};
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name] || !!this.$scopedSlots[name];
    },
    onClick(e) {
      e.preventDefault();

      if (this.loading || this.disabled) {
        return;
      }
      this.$emit('click', e);
    },
  },
  mounted() {
    this.$on('click', (event) => {
      if (!this.href) {
        return;
      }

      if (event.metaKey || this.$props.targetUrl) {
        window.open(this.href);
        return true;
      }

      this.$router.push(this.$props.to).catch(() => {
      });
    });
  },
  updated() {
  },
  computed: {
    href() {
      if (!this.$props.to) {
        return this.$props.targetUrl;
      }

      const { href } = this.$router.resolve(this.$props.to);
      return href; // for accessibility
    },
  },
};
</script>

<style lang="scss" scoped>
.ciam-button {
  @apply select-none font-medium text-indigo-600 hover:text-indigo-500;

  &.primary {
    @apply cursor-pointer inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  &.secondary {
    @apply cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500;
  }

  &.danger {
    @apply cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
  }

  &.warning-outline {
    @apply cursor-pointer inline-flex items-center px-4 py-2  ring-1 ring-inset ring-yellow-500 bg-white hover:bg-yellow-200 text-sm rounded-md text-black;
  }

  &.disabled {
    @apply opacity-20 transition-all select-none cursor-not-allowed inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-gray-700 bg-gray-200 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500;

    &.error {
      @apply opacity-80 transition-all select-none cursor-not-allowed inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded-md text-red-700 bg-red-200 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500;
    }
  }

  &.white {
    @apply cursor-pointer inline-flex items-center justify-center px-4 py-2 bg-white text-sm font-medium border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 outline-none;

    &.center {
      @apply rounded-none;
    }

    &.left {
      @apply rounded-none rounded-l-lg;
    }

    &.right {
      @apply rounded-none rounded-r-lg;
    }

    &.active {
      @apply bg-gray-50 border-gray-300;
    }
  }

  &[disabled='disabled'] {
    @apply opacity-20 transition-all;
  }
}

.ciam-button-left {
  @apply -ml-1 mb-1 h-5 w-5;
}

.ciam-button-right {
  @apply ml-3 -mr-1 h-5 w-5;
}
</style>
