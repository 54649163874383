<template>
  <div v-bind="{ ...$props, ...$attrs }" :class="[bg, 'card']">
    <slot></slot>
  </div>
</template>

<script>
const Styles = {
  white: 'white',
  gray: 'gray',
};
export default {
  name: 'ciam-card',
  props: {
    bg: {
      type: String,
      required: false,
      default: Object.keys(Styles)[0],
      validator: (val) => Object.prototype.hasOwnProperty.call(Styles, val),
    },
  },
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.card {
  @apply bg-white shadow overflow-hidden sm:rounded-lg;

  &.gray {
    @apply bg-gray-100;
  }

  &.white {
    @apply bg-white;
  }
}
</style>
