<template>
  <ciam-link
    v-bind="{ ...$props, ...$attrs }"
    class="hover:bg-gray-100 hover:text-gray-900 text-gray-700 block mb-0 px-4 py-2 text-sm"
    role="menuitem"
    tabindex="-1"
    id="menu-item-0"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </ciam-link>
</template>

<script>
import CiamLink from '@/components/CiamLink';

export default {
  name: 'ciam-dropdown-menu-item-link',
  props: {},
  components: {
    CiamLink,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>


