<template>
  <span class="text" v-bind="{ ...$props, ...$attrs }">
    <slot></slot>
  </span>
</template>

<script>
export default {
  name: 'ciam-text',
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.text {
  @apply font-normal;

  &.medium {
    @apply font-medium;
  }

  &.bold {
    @apply font-bold;
  }
}
</style>
