<template>
  <div>
    <b-modal
      :active.sync="isOpened"
      has-modal-card
      trap-focus
      :can-cancel="['button']"
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      :width="600"
    >
      <standard-modal v-if="isOpened" :buttons="buttons" :item="credentials">
        <template slot="header">
          <h1>{{ $t('DeploymentNewModal.title') }}</h1>
        </template>
        <template v-slot:default="props">
          <p>
            {{ $t('DeploymentNewModal.description') }}
          </p>

          <p class="warning" :class="blinkAnimation">
            <span class="picto">⚠</span>
            <span>
              <b>{{ $t('DeploymentNewModal.warning') }} : </b>
              {{ $t('DeploymentNewModal.warningMessage') }}</span
            >
          </p>
          <hr />
          <template v-if="props.item.dedicated">
            <p>
              <strong>{{ $t('DeploymentNewModal.adminPassword') }} : </strong>
              <password-field :password="props.item.keycloakAdminPassword" />
            </p>
          </template>
          <template v-else>
            <p>
              <strong>{{ $t('DeploymentNewModal.username') }} : </strong>
              <password-field :always="true" :password="props.item.adminUsername" />
            </p>
            <p>
              <strong>{{ $t('DeploymentNewModal.password') }} : </strong>
              <password-field :always="true" :password="props.item.adminPassword" />
            </p>
          </template>
        </template>
      </standard-modal>
    </b-modal>
  </div>
</template>

<script>
import StandardModal from '@/components/StandardModal';
import PasswordField from '@/components/PasswordField';
import { bus } from '@/main';

const DELAY_BUTTON_SEC = 5;

export default {
  name: 'DeploymentCredentialsModal',
  components: {
    PasswordField,
    StandardModal,
  },
  data() {
    return {
      credentials: null,
      isOpened: false,
      notifyWarning: false,
      buttons: [
        {
          text: this.$t('DeploymentNewModal.close'),
          classes: ['is-danger', 'is-outlined'],
          delayed: DELAY_BUTTON_SEC,
        },
      ],
    };
  },
  methods: {
    show() {
      this.isOpened = true;
      this.notifyWarning = false;
      setTimeout(() => (this.notifyWarning = true), DELAY_BUTTON_SEC * 1000);
    },
  },
  mounted() {
    bus.on('deployment-creation-received', (credentials) => {
      this.credentials = credentials;
      this.show();
    });
    this.buttons.forEach((b) => (b.delayed$ = b.delayed));
  },
  computed: {
    blinkAnimation() {
      return !this.notifyWarning || 'blink';
    },
  },
};
</script>

<style>
.modal-card-body {
  overflow-x: hidden !important;
}
</style>

<style lang="scss" scoped>
.warning {
  display: flex;
  flex-direction: row;
  span {
    &:first-child {
      margin: -15px 15px 0 15px;
      font-size: 64px;
    }
  }
}

@keyframes blink-warning {
  50% {
    background-color: #ff3860;
    color: white;
    transform: scale(1.1);
  }
  100% {
    color: #ff3860;
  }
}

.blink {
  animation: blink-warning 1s ease forwards;
}
</style>
