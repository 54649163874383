<template>
  <a v-bind="{ ...$props, ...$attrs }" @click="onClick($event)">
    <slot></slot>
    <ciam-icon style="margin-left: 0.2rem" name="fa-external-link" v-if="isTargetBlank" />
  </a>
</template>

<script>
export default {
  name: 'ciam-link',
  components: {},
  data() {
    return {};
  },
  props: {
    targetIcon: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onClick(e) {
      this.$emit('click', e);
    },
  },
  mounted() {},
  computed: {
    isTargetBlank() {
      return this.targetIcon && this.$attrs && this.$attrs.target && this.$attrs.target == '_blank';
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  @apply font-medium text-indigo-600 hover:text-indigo-500;

  &.danger {
    @apply text-red-700 hover:text-red-600;
  }
}
</style>
