import Vue from 'vue';
import VueI18n from 'vue-i18n';
import getBrowserLocale from '@/util/get-browser-locale';

Vue.use(VueI18n);

function loadLocaleMessages() {
  return {
    en: require('./locales/en.json'),
  };
}

export function supportedLocalesInclude(locale) {
  const supportedLocales = { en: 'English' };
  return Object.keys(supportedLocales).includes(locale);
}

export function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  if (supportedLocalesInclude(browserLocale)) {
    return browserLocale;
  } else {
    return process.env.VUE_APP_I18N_LOCALE || 'en';
  }
}

// Expose {t: Fn}
export default new VueI18n({
  locale: getStartingLocale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages(),
  silentFallbackWarn: true,
});
