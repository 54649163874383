<template>
  <nav class="bg-white shadow">
    <div class="px-4 sm:px-6 max-w-6xl mx-auto">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex-shrink-0 flex items-center">
            <router-link class="navbar-item" :to="{ path: '/' }">
              <img src="../assets/logo/icon-color.svg" />
            </router-link>
            <slot name="left"></slot>
          </div>
          <ciam-tabs :tabs="tabs" kind="navigation"></ciam-tabs>
        </div>
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <slot name="right"></slot>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import CiamTabs from '@/components/CiamTabs';
import { routeNames } from '@/router';

export default {
  name: 'ciam-navigation',
  components: {
    CiamTabs,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {
    tabs() {
      return (
        // at launch time the querystring might not have organization_id
        // that's why we added this condition. The query string will always have the organization_id some ms later because
        // of the router middleware side-effect
        (
          this.$route.query.organization_id
            ? [
                {
                  id: routeNames.DeploymentList,
                  name: this.$t('menu.deploymentList'),
                  route: {
                    name: routeNames.DeploymentList,
                    query: {
                      organization_id: this.$route.query.organization_id,
                    },
                  },
                },
                {
                  id: routeNames.OrganizationDetails,
                  name: this.$t('organization.menu.organization'),
                  route: {
                    name: routeNames.OrganizationDetails,
                    params: {
                      id: this.$route.query.organization_id,
                    },
                    query: {
                      organization_id: this.$route.query.organization_id,
                    },
                  },
                },
              ]
            : []
        ).concat([
          {
            id: routeNames.DocumentationAPI,
            name: this.$t('menu.documentationApi'),
            route: {
              name: routeNames.DocumentationAPI,
            },
          },
          {
            id: routeNames.TicketList,
            name: this.$t('menu.ticketCenter'),
            route: {
              name: routeNames.TicketList,
            },
          },

          // {
          //   id: routeNames.ReportIncident,
          //   name: 'Trigger incident',
          //   route: {
          //     name: routeNames.ReportIncident,
          //   },
          // },
        ])
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar-item {
  max-height: 100%;

  img {
    max-height: 3rem;
    height: 100%;
    width: 100%;
    image-rendering: smooth;
    min-width: 60px;
    max-width: 80px;
  }
}

.footer-container {
  @apply max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8;
}

.navigation-items {
  @apply hidden sm:ml-6 sm:flex sm:space-x-8;
}

.navigation-item {
  @apply border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium;
}

.navigation-item--active {
  @apply border-indigo-500 text-gray-900 inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium;
}
</style>
