<template>
  <div class="py-1">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'ciam-dropdown-menu-items',
  props: {},
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
  computed: {},
};
</script>


