import axios from 'axios';

export default class KeycloakService {
  static async getOpenidConfiguration(appName) {
    return await axios.get(`https://${appName}.cloud-iam.com/auth/realms/cloud-iam/.well-known/openid-configuration`).then((response) => response.data.issuer);
  }
  static async getSignature(appName) {
    return await axios.get(`https://${appName}.cloud-iam.com/auth/realms/cloud-iam/protocol/openid-connect/certs`).then((resp) => resp.data.keys[0])
  }
}
