<template>
  <div class="ciam-card-header" :class="{ 'with-tabs': hasSlot('tabs') }">
    <div class="ciam-card-header-container">
      <div class="ciam-card-header-title-and-subtitle">
        <h3 class="ciam-card-header-title">
          <slot name="header"></slot>
        </h3>
        <p class="ciam-card-header-subtitle">
          <slot name="subtitle"></slot>
        </p>
      </div>
      <div class="ciam-card-header-actions">
        <slot name="actions"></slot>
      </div>
    </div>

    <div class="mt-4">
      <slot name="tabs"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ciam-card-header',
  components: {},
  data() {
    return {};
  },
  methods: {
    hasSlot(name = 'default') {
      return !!this.$slots[name];
    },
  },
  mounted() {},
  computed: {},
};
</script>

<style lang="scss" scoped>
.ciam-card-header {
  @apply bg-white px-4 py-5 border-b border-gray-200 sm:px-6;

  &.with-tabs {
    @apply border-0 pb-0;
  }

  .ciam-card-header-container {
    @apply flex items-center justify-between;
  }

  .ciam-card-header-title-and-subtitle {
    width: 100%;
    .ciam-card-header-title {
      @apply text-lg leading-6 font-medium text-gray-900;
    }

    .ciam-card-header-subtitle {
      @apply mt-1 text-sm text-gray-500;
    }
  }

  .ciam-card-header-actions {
    @apply flex-shrink-0;
    & > * {
      @apply ml-4;
    }
  }
}
</style>
